var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-overlay',{attrs:{"opacity":"0.17","blur":"1rem","show":_vm.showOverlay,"rounded":"md","variant":"secondary"}},[(_vm.plan && _vm.plan != null)?_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header pb-75",staticStyle:{"padding-right":"0"}},[_c('div',{staticClass:"w-100 row justify-content-between align-items-start"},[_c('div',{staticClass:"d-flex flex-column align-items-start justify-content-start col-md-6 col-sm-6"},[_c('h3',{staticClass:"mb-25 font-weight-bolder"},[_vm._v(_vm._s(_vm.plan.title))]),_c('p',{staticClass:"mb-50"},[_vm._v(_vm._s(_vm.plan.description))]),_c('div',{staticClass:"d-flex align-items-start justify-content-start mt-1"},[_c('div',{staticClass:"d-flex flex-column align-items-start justify-content-start mr-4"},[_c('label',{staticClass:"font-weight-bolder"},[_vm._v("Active From")]),_c('p',[_vm._v(_vm._s(_vm._f("moment")(_vm.plan.prices[0].active_from)))])]),_c('div',{staticClass:"d-flex flex-column align-items-start justify-content-start"},[_c('label',{staticClass:"font-weight-bolder"},[_vm._v("Active To")]),_c('p',[_vm._v(_vm._s(_vm._f("moment")(_vm.plan.prices[0].active_to)))])])])]),_c('div',{staticClass:"d-flex flex-row align-items-start justify-content-end col-sm-4 col-md-6"},[_c('div',{staticClass:"d-flex align-items-center justify-content-center border-primary px-25 mr-1 rounded",class:{
              'bg-light-success': _vm.plan.status && _vm.plan.status == 1,
              'border-success': _vm.plan.status && _vm.plan.status == 1,
            },staticStyle:{"padding-top":"3px","padding-bottom":"3px"}},[_c('b-form-checkbox',{staticClass:"custom-control-success",attrs:{"value":1,"unchecked-value":2,"switch":"","inline":""},on:{"change":function (value) { return _vm.checkboxChanged(value, _vm.plan._id); }},model:{value:(_vm.plan.status),callback:function ($$v) {_vm.$set(_vm.plan, "status", $$v)},expression:"plan.status"}},[_vm._v(" Active ")])],1),_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.left.v-warning",modifiers:{"left":true,"v-warning":true}}],attrs:{"title":_vm.plan.status && _vm.plan.status == 1
                ? 'Action not allowed while plan status is active.'
                : null}},[_c('b-button',{attrs:{"disabled":_vm.plan.status && _vm.plan.status == 1,"size":"sm","variant":"outline-info"},on:{"click":function($event){return _vm.handleEditClick(_vm.plan._id)}}},[_c('feather-icon',{staticClass:"mr-25",attrs:{"icon":"EditIcon"}}),_c('span',{staticClass:"align-middle"},[_vm._v("Edit")])],1)],1)])])]),_c('div',{staticClass:"card-body"},[_c('b-tabs',{attrs:{"content-class":"mt-1"}},[_c('b-tab',{attrs:{"active":""},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('span',[_vm._v("Features "),_c('b-badge',{staticClass:"ml-25",attrs:{"pill":"","variant":"warning"}},[_vm._v(_vm._s(_vm.plan.features && _vm.plan.features != null ? _vm.plan.features.length : 0))])],1)]},proxy:true}],null,false,934380551)},[_c('div',{staticClass:"position-relative table-responsive"},[_c('div',{staticClass:"table-responsive",staticStyle:{"min-height":"50vh"}},[_c('table',{staticClass:"table",attrs:{"role":"table"}},[_c('thead',{attrs:{"role":"rowgroup"}},[_c('tr',{attrs:{"role":"row"}},[_c('th',{attrs:{"scope":"col"}},[_vm._v("#")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Name")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Description")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Source")]),_c('th',{staticClass:"text-center",attrs:{"scope":"col"}},[_vm._v("Quantity")])])]),_c('tbody',{attrs:{"role":"rowgroup"}},[(
                      _vm.plan.features &&
                      _vm.plan.features != null &&
                      _vm.plan.features.length > 0
                    )?_vm._l((_vm.plan.features),function(feature,index){return _c('tr',{key:feature._id['$oid'],attrs:{"role":"row"}},[_c('td',{staticStyle:{"width":"120px"},attrs:{"scope":"row"}},[_vm._v(" "+_vm._s(index + 1)+" ")]),_c('td',{attrs:{"role":"cell"}},[_c('div',{staticClass:"d-flex flex-column align-items-start justify-content-start"},[_c('p',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.bottom.v-primary",modifiers:{"hover":true,"bottom":true,"v-primary":true}}],staticClass:"mb-0 font-weight-bold text-truncate",staticStyle:{"max-width":"15vw"},attrs:{"title":feature.title}},[_vm._v(" "+_vm._s(("" + (feature.title)))+" ")])])]),_c('td',{attrs:{"role":"cell"}},[_c('div',{staticClass:"d-flex flex-column align-items-start justify-content-start"},[_c('p',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.bottom.v-primary",modifiers:{"hover":true,"bottom":true,"v-primary":true}}],staticClass:"mb-0 font-weight-bold text-truncate",staticStyle:{"max-width":"20vw"},attrs:{"title":feature.description}},[_vm._v(" "+_vm._s(("" + (feature.description)))+" ")])])]),_c('td',{attrs:{"role":"cell"}},[_c('div',{staticClass:"d-flex flex-column align-items-start justify-content-start"},[(feature.module && feature.module != null)?_c('b-badge',{attrs:{"variant":"primary"}},[_vm._v(_vm._s(feature.module))]):_vm._e()],1)]),_c('td',{attrs:{"role":"cell"}},[(feature.unit && feature.unit != null)?_c('div',{staticClass:"d-flex align-items-center justify-content-center w-100"},[(
                              feature.unit &&
                              feature.unit == 'count' &&
                              feature.quantity
                            )?_c('div',{staticClass:"d-flex flex-column align-items-center justify-content-center"},[_c('label',{staticClass:"font-weight-bolder text-primary"},[_vm._v("Count")]),_c('b-badge',{attrs:{"variant":"dark"}},[_vm._v(_vm._s(feature.quantity))])],1):(
                              feature.unit &&
                              feature.unit == 'credit' &&
                              feature.tariff
                            )?_c('div',{staticClass:"d-flex flex-column align-items-center justify-content-center"},[_c('label',{staticClass:"font-weight-bolder text-primary"},[_vm._v("Credits Per Unit")]),_c('b-badge',{attrs:{"variant":"dark"}},[_vm._v(_vm._s(feature.tariff))])],1):(
                              feature.unit && feature.unit == 'boolean'
                            )?_c('div',{staticClass:"d-flex flex-column align-items-start justify-content-center"},[_c('label',{staticClass:"font-weight-bolder text-success"},[_vm._v("Enabled")]),_c('b-form-checkbox',{staticClass:"custom-control-success",attrs:{"checked":"true","name":"check-button","switch":"","disabled":true}},[_c('span',{staticClass:"switch-icon-left"},[_c('feather-icon',{attrs:{"icon":"CheckIcon"}})],1)])],1):_vm._e()]):_vm._e()])])}):[_c('empty-table-section',{attrs:{"title":"Features Empty"},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('p',{staticClass:"font-weight-bold text-center"},[_vm._v(" No features have been found. ")])]},proxy:true}],null,false,808016592)})]],2)])])])]),_c('b-tab',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('span',[_vm._v("Prices "),_c('b-badge',{staticClass:"ml-25",attrs:{"pill":"","variant":"warning"}},[_vm._v(_vm._s(_vm.plan.prices && _vm.plan.prices != null ? _vm.plan.prices.length : 0))])],1)]},proxy:true}],null,false,1657360679)},[_c('div',{staticClass:"position-relative table-responsive"},[_c('div',{staticClass:"table-responsive",staticStyle:{"min-height":"50vh"}},[_c('table',{staticClass:"table table-hover",attrs:{"role":"table"}},[_c('thead',{attrs:{"role":"rowgroup"}},[_c('tr',{attrs:{"role":"row"}},[_c('th',{attrs:{"scope":"col"}},[_vm._v("#")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Name")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Duration(Days)")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Price(USD)")])])]),_c('tbody',{attrs:{"role":"rowgroup"}},[(
                      _vm.plan.prices &&
                      _vm.plan.prices != null &&
                      _vm.plan.prices.length > 0
                    )?_vm._l((_vm.plan.prices),function(price,index){return _c('tr',{key:price._id['$oid'],attrs:{"role":"row"}},[_c('td',{staticStyle:{"width":"120px"},attrs:{"scope":"row"}},[_vm._v(" "+_vm._s(index + 1)+" ")]),_c('td',{attrs:{"role":"cell"}},[_c('div',{staticClass:"d-flex flex-column align-items-start justify-content-start"},[_c('p',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.bottom.v-primary",modifiers:{"hover":true,"bottom":true,"v-primary":true}}],staticClass:"mb-0 font-weight-bold text-truncate",staticStyle:{"max-width":"15vw"},attrs:{"title":price.name}},[_vm._v(" "+_vm._s(("" + (price.name)))+" ")])])]),_c('td',{attrs:{"role":"cell"}},[_c('div',{staticClass:"d-flex flex-column align-items-start justify-content-start"},[_c('p',{staticClass:"mb-0 font-weight-bold text-truncate",staticStyle:{"max-width":"20vw"}},[_vm._v(" "+_vm._s(("" + (price.duration)))+" ")])])]),_c('td',{attrs:{"role":"cell"}},[(price.tariff && price.tariff != null)?_c('div',{staticClass:"d-flex flex-column align-items-start justify-content-start"},[_c('p',{staticClass:"mb-0 font-weight-bold text-truncate"},[_vm._v(" "+_vm._s(("" + (price.tariff)))+" ")])]):_vm._e()])])}):[_c('empty-table-section',{attrs:{"title":"Prices Empty"},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('p',{staticClass:"font-weight-bold text-center"},[_vm._v(" No prices have been found. ")])]},proxy:true}],null,false,1021985737)})]],2)])])])]),_c('b-tab',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('span',[_vm._v("Subscribers "),_c('b-badge',{staticClass:"ml-25",attrs:{"pill":"","variant":"warning"}},[_vm._v(_vm._s(_vm.subscribers && _vm.subscribers != null ? _vm.subscribers.length : 0))])],1)]},proxy:true}],null,false,1398933082)},[_c('plan-details-subscriptions',{attrs:{"subscribers":_vm.subscribers}})],1)],1)],1)]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }