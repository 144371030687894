<template>
  <b-overlay
    opacity="0.17"
    blur="1rem"
    :show="showOverlay"
    rounded="md"
    variant="secondary"
  >
    <div class="card" v-if="plan && plan != null">
      <div class="card-header pb-75" style="padding-right: 0">
        <div class="w-100 row justify-content-between align-items-start">
          <div
            class="d-flex flex-column align-items-start justify-content-start col-md-6 col-sm-6"
          >
            <h3 class="mb-25 font-weight-bolder">{{ plan.title }}</h3>
            <p class="mb-50">{{ plan.description }}</p>

            <div class="d-flex align-items-start justify-content-start mt-1">
              <div
                class="d-flex flex-column align-items-start justify-content-start mr-4"
              >
                <label class="font-weight-bolder">Active From</label>
                <p>{{ plan.prices[0].active_from | moment }}</p>
              </div>

              <div
                class="d-flex flex-column align-items-start justify-content-start"
              >
                <label class="font-weight-bolder">Active To</label>
                <p>{{ plan.prices[0].active_to | moment }}</p>
              </div>
            </div>
          </div>

          <div
            class="d-flex flex-row align-items-start justify-content-end col-sm-4 col-md-6"
          >
            <div
              class="d-flex align-items-center justify-content-center border-primary px-25 mr-1 rounded"
              style="padding-top: 3px; padding-bottom: 3px"
              :class="{
                'bg-light-success': plan.status && plan.status == 1,
                'border-success': plan.status && plan.status == 1,
              }"
            >
              <b-form-checkbox
                @change="(value) => checkboxChanged(value, plan._id)"
                v-model="plan.status"
                :value="1"
                :unchecked-value="2"
                class="custom-control-success"
                switch
                inline
              >
                <!-- {{ plan.status && plan.status == 1 ? "Active" : "Deactive" }} -->
                Active
              </b-form-checkbox>
            </div>

            <span
              v-b-tooltip.left.v-warning
              :title="
                plan.status && plan.status == 1
                  ? 'Action not allowed while plan status is active.'
                  : null
              "
            >
              <b-button
                :disabled="plan.status && plan.status == 1"
                size="sm"
                variant="outline-info"
                @click="handleEditClick(plan._id)"
                ><feather-icon icon="EditIcon" class="mr-25" /><span
                  class="align-middle"
                  >Edit</span
                ></b-button
              >
            </span>
          </div>
        </div>
      </div>
      <div class="card-body">
        <b-tabs content-class="mt-1">
          <b-tab active>
            <template #title>
              <span
                >Features
                <b-badge class="ml-25" pill variant="warning">{{
                  plan.features && plan.features != null
                    ? plan.features.length
                    : 0
                }}</b-badge></span
              >
            </template>
            <div class="position-relative table-responsive">
              <div class="table-responsive" style="min-height: 50vh">
                <table role="table" class="table">
                  <thead role="rowgroup">
                    <tr role="row">
                      <th scope="col">#</th>
                      <th scope="col">Name</th>
                      <th scope="col">Description</th>
                      <th scope="col">Source</th>
                      <th scope="col" class="text-center">Quantity</th>
                      <!-- <th scope="col">Tariff</th> -->
                    </tr>
                  </thead>
                  <tbody role="rowgroup">
                    <template
                      v-if="
                        plan.features &&
                        plan.features != null &&
                        plan.features.length > 0
                      "
                    >
                      <tr
                        role="row"
                        v-for="(feature, index) in plan.features"
                        :key="feature._id['$oid']"
                      >
                        <td scope="row" style="width: 120px">
                          {{ index + 1 }}
                        </td>

                        <td role="cell">
                          <div
                            class="d-flex flex-column align-items-start justify-content-start"
                          >
                            <p
                              class="mb-0 font-weight-bold text-truncate"
                              style="max-width: 15vw"
                              v-b-tooltip.hover.bottom.v-primary
                              :title="feature.title"
                            >
                              {{ `${feature.title}` }}
                            </p>
                          </div>
                        </td>

                        <td role="cell">
                          <div
                            class="d-flex flex-column align-items-start justify-content-start"
                          >
                            <p
                              class="mb-0 font-weight-bold text-truncate"
                              style="max-width: 20vw"
                              v-b-tooltip.hover.bottom.v-primary
                              :title="feature.description"
                            >
                              {{ `${feature.description}` }}
                            </p>
                          </div>
                        </td>

                        <td role="cell">
                          <div
                            class="d-flex flex-column align-items-start justify-content-start"
                          >
                            <b-badge
                              v-if="feature.module && feature.module != null"
                              variant="primary"
                              >{{ feature.module }}</b-badge
                            >
                          </div>
                        </td>

                        <td role="cell">
                          <div
                            class="d-flex align-items-center justify-content-center w-100"
                            v-if="feature.unit && feature.unit != null"
                          >
                            <div
                              v-if="
                                feature.unit &&
                                feature.unit == 'count' &&
                                feature.quantity
                              "
                              class="d-flex flex-column align-items-center justify-content-center"
                            >
                              <label class="font-weight-bolder text-primary"
                                >Count</label
                              >
                              <b-badge variant="dark">{{
                                feature.quantity
                              }}</b-badge>
                            </div>

                            <div
                              v-else-if="
                                feature.unit &&
                                feature.unit == 'credit' &&
                                feature.tariff
                              "
                              class="d-flex flex-column align-items-center justify-content-center"
                            >
                              <label class="font-weight-bolder text-primary"
                                >Credits Per Unit</label
                              >
                              <b-badge variant="dark">{{
                                feature.tariff
                              }}</b-badge>
                            </div>

                            <div
                              v-else-if="
                                feature.unit && feature.unit == 'boolean'
                              "
                              class="d-flex flex-column align-items-start justify-content-center"
                            >
                              <label class="font-weight-bolder text-success"
                                >Enabled</label
                              >
                              <b-form-checkbox
                                checked="true"
                                class="custom-control-success"
                                name="check-button"
                                switch
                                :disabled="true"
                              >
                                <span class="switch-icon-left">
                                  <feather-icon icon="CheckIcon" />
                                </span>
                                <!-- <span class="switch-icon-right">
                                <feather-icon icon="BellOffIcon" />
                              </span> -->
                              </b-form-checkbox>
                            </div>
                          </div>
                        </td>

                        <!-- <td role="cell">
                    <div
                      class="d-flex flex-column align-items-start justify-content-start"
                      v-if="feature.quantity && feature.quantity != null"
                    >
                      <p
                        class="mb-0 font-weight-bold text-truncate"
                        v-b-tooltip.hover.bottom.v-primary
                        :title="feature.quantity"
                      >
                        {{ `${feature.quantity}` }}
                      </p>
                    </div>
                  </td> -->
                      </tr>
                    </template>
                    <template v-else>
                      <empty-table-section title="Features Empty">
                        <template #content>
                          <p class="font-weight-bold text-center">
                            No features have been found.
                          </p>
                        </template>
                      </empty-table-section>
                    </template>
                  </tbody>
                </table>
              </div>
            </div>
          </b-tab>

          <b-tab>
            <template #title>
              <span
                >Prices
                <b-badge class="ml-25" pill variant="warning">{{
                  plan.prices && plan.prices != null ? plan.prices.length : 0
                }}</b-badge></span
              >
            </template>
            <div class="position-relative table-responsive">
              <div class="table-responsive" style="min-height: 50vh">
                <table role="table" class="table table-hover">
                  <thead role="rowgroup">
                    <tr role="row">
                      <th scope="col">#</th>
                      <th scope="col">Name</th>
                      <!-- <th scope="col">Description</th> -->
                      <th scope="col">Duration(Days)</th>
                      <th scope="col">Price(USD)</th>
                      <!-- <th scope="col">Total Credit</th> -->
                      <!-- <th scope="col">Tariff</th> -->
                    </tr>
                  </thead>
                  <tbody role="rowgroup">
                    <template
                      v-if="
                        plan.prices &&
                        plan.prices != null &&
                        plan.prices.length > 0
                      "
                    >
                      <tr
                        role="row"
                        v-for="(price, index) in plan.prices"
                        :key="price._id['$oid']"
                      >
                        <td scope="row" style="width: 120px">
                          {{ index + 1 }}
                        </td>

                        <td role="cell">
                          <div
                            class="d-flex flex-column align-items-start justify-content-start"
                          >
                            <p
                              class="mb-0 font-weight-bold text-truncate"
                              style="max-width: 15vw"
                              v-b-tooltip.hover.bottom.v-primary
                              :title="price.name"
                            >
                              {{ `${price.name}` }}
                            </p>
                          </div>
                        </td>

                        <!-- <td role="cell">
                        <div
                          class="d-flex flex-column align-items-start justify-content-start"
                        >
                          <p
                            class="mb-0 font-weight-bold text-truncate"
                            style="max-width: 20vw"
                            v-b-tooltip.hover.bottom.v-primary
                            :title="price.description"
                          >
                            {{ `${price.description}` }}
                          </p>
                        </div>
                      </td> -->

                        <td role="cell">
                          <div
                            class="d-flex flex-column align-items-start justify-content-start"
                          >
                            <p
                              class="mb-0 font-weight-bold text-truncate"
                              style="max-width: 20vw"
                            >
                              {{ `${price.duration}` }}
                            </p>
                          </div>
                        </td>

                        <td role="cell">
                          <div
                            class="d-flex flex-column align-items-start justify-content-start"
                            v-if="price.tariff && price.tariff != null"
                          >
                            <p class="mb-0 font-weight-bold text-truncate">
                              {{ `${price.tariff}` }}
                            </p>
                          </div>
                        </td>

                        <!-- <td role="cell">
                        <div
                          class="d-flex flex-column align-items-start justify-content-start"
                          v-if="
                            price.allowed_total_credit &&
                            price.allowed_total_credit != null
                          "
                        >
                          <p class="mb-0 font-weight-bold text-truncate">
                            {{ `${price.allowed_total_credit}` }}
                          </p>
                        </div>
                      </td> -->

                        <!-- <td role="cell">
                    <div
                      class="d-flex flex-column align-items-start justify-content-start"
                      v-if="feature.quantity && feature.quantity != null"
                    >
                      <p
                        class="mb-0 font-weight-bold text-truncate"
                        v-b-tooltip.hover.bottom.v-primary
                        :title="feature.quantity"
                      >
                        {{ `${feature.quantity}` }}
                      </p>
                    </div>
                  </td> -->
                      </tr>
                    </template>
                    <template v-else>
                      <empty-table-section title="Prices Empty">
                        <template #content>
                          <p class="font-weight-bold text-center">
                            No prices have been found.
                          </p>
                        </template>
                      </empty-table-section>
                    </template>
                  </tbody>
                </table>
              </div>
            </div>
          </b-tab>

          <b-tab>
            <template #title>
              <span
                >Subscribers
                <b-badge class="ml-25" pill variant="warning">{{
                  subscribers && subscribers != null ? subscribers.length : 0
                }}</b-badge></span
              >
            </template>
            <plan-details-subscriptions :subscribers="subscribers" />
          </b-tab>
        </b-tabs>
      </div>
    </div>
  </b-overlay>
</template>

<script>
import PlanMixins from "../../mixins/PlanMixins";
import ResponseMixins from "../../mixins/ResponseMixins";
import {
  BButton,
  BBadge,
  VBTooltip,
  BCollapse,
  VBToggle,
  BTab,
  BTabs,
  BFormCheckbox,
  BOverlay,
} from "bootstrap-vue";
import EmptyTableSection from "../components/EmptyTableSection.vue";
import moment from "moment";
import PlanDetailsSubscriptions from "./components/PlanDetailsSubscriptions.vue";
export default {
  components: {
    BButton,
    EmptyTableSection,
    BBadge,
    BCollapse,
    BTab,
    BTabs,
    BFormCheckbox,
    moment,
    BOverlay,
    PlanDetailsSubscriptions,
  },
  directives: {
    "b-tooltip": VBTooltip,
    "b-toggle": VBToggle,
  },
  mixins: [PlanMixins, ResponseMixins],

  data() {
    return {
      plan: null,
      subscribers: [],
      showOverlay: false,
    };
  },

  filters: {
    moment: function (date) {
      return moment(date).format("Do MMMM YYYY");
    },
  },

  mounted() {
    this.load();
  },

  methods: {
    load() {
      this.getPlanDetailsAndSetData(this.$route.params.id);
      this.getPlanSubscribersAndSetData(this.$route.params.id);
    },

    checkboxChanged(value, id) {
      this.showOverlay = true;
      console.log(value);
      this.setPlanStatus(value, id)
        .then((res) => {
          console.log(res);
          this.handleResponse(res);
          this.load();
        })
        .catch((err) => {
          this.handleError(err);
          console.log(err);
        })
        .finally(() => {
          this.showOverlay = false;
        });
    },

    handleEditClick(id) {
      this.$router.push(`/edit-plan/${id}`);
    },

    getPlanDetailsAndSetData(id) {
      this.showOverlay = true;

      this.getPlan(id)
        .then((res) => {
          console.log(res);
          this.plan = res.data.data;
        })
        .catch((err) => {
          this.handleError(err);
          console.log(err);
        })
        .finally(() => {
          this.showOverlay = false;
        });
    },

    getPlanSubscribersAndSetData(id) {
      this.showOverlay = true;

      this.getPlanSubscribers(id)
        .then((res) => {
          console.log(res);
          this.subscribers = res.data.data;
        })
        .catch((err) => {
          this.handleError(err);
          console.log(err);
        })
        .finally(() => {
          this.showOverlay = false;
        });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
th {
  background: #472183 !important;
  color: #fff;
  text-transform: capitalize !important;
}
</style>
  
<style lang="css" scoped>
.table th {
  text-transform: none;
}
</style>
  
<style lang="scss">
.app-fixed-search {
  background-color: transparent;

  .input-group:focus-within {
    box-shadow: none;
  }

  input,
  .input-group-text {
    border: 0;
    background-color: transparent;
  }
}
</style>