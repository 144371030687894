<template>
  <div class="position-relative table-responsive">
    <div class="table-responsive" style="min-height: 50vh">
      <table role="table" class="table table-hover">
        <thead role="rowgroup">
          <tr role="row">
            <th scope="col">#</th>
            <th scope="col">Name</th>
            <th scope="col">Status</th>
            <th scope="col">Effective Till</th>
          </tr>
        </thead>
        <tbody role="rowgroup">
          <template
            v-if="subscribers && subscribers != null && subscribers.length > 0"
          >
            <tr
              role="row"
              v-for="(subscriber, index) in subscribers"
              :key="subscriber._id"
            >
              <td scope="row" style="width: 120px">
                {{ index + 1 }}
              </td>

              <td role="cell">
                <div
                  class="d-flex flex-column align-items-start justify-content-start"
                >
                  <p
                    class="mb-0 font-weight-bold"
                    style="max-width: 15vw"
                    :title="subscriber.name"
                  >
                    {{ subscriber.name }}
                  </p>
                </div>
              </td>

              <td role="cell">
                <div class="d-flex align-items-center justify-content-start">
                  <b-badge v-if="subscriber.is_active" variant="success"
                    >Active</b-badge
                  >
                  <b-badge v-else variant="dark">Inactive</b-badge>
                </div>
              </td>

              <td role="cell">
                {{ subscriber.plan_effective_to | moment }}
              </td>
            </tr>
          </template>
          <template v-else>
            <empty-table-section title="No Subscribers">
              <template #content>
                <p class="font-weight-bold text-center">
                  No subscribers have been found.
                </p>
              </template>
            </empty-table-section>
          </template>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import PlanMixins from "../../../mixins/PlanMixins";
import ResponseMixins from "../../../mixins/ResponseMixins";
import EmptyTableSection from "../../components/EmptyTableSection.vue";
import { BBadge } from "bootstrap-vue";
import moment from "moment";

export default {
  components: { EmptyTableSection, BBadge, moment },
  mixins: [PlanMixins, ResponseMixins],

  props: {
    subscribers: {
      type: Array,
      required: true,
    },
  },

  filters: {
    moment: function (date) {
      return moment(date).format("Do MMMM YYYY");
    },
  },
};
</script>

<style>
</style>